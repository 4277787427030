<template>
  <div class="cont">
     <el-row>
        <el-link class="l-height-50 back_iconfont" type="primary" icon="iconfont iconfanhui1" @click="goBack()">
           <span>返回</span>
        </el-link>
     </el-row>
     <el-row>
        <el-divider content-position="left"><span>基本信息</span></el-divider>
        <el-row class="info-main">
           <el-row>
              <el-col :span="8">工单分类：{{detailData.typeCode}}</el-col>
              <el-col :span="8">工单标题：{{detailData.title}}</el-col>
              <el-col :span="8">负责人：{{detailData.receiveName}}</el-col>
           </el-row>
           <el-row>
              <el-col :span="8">工号：{{detailData.updateOperator}}</el-col>
              <el-col :span="8">联系方式：{{detailData.receivePhone}}</el-col>
              <el-col :span="8">所属部门：{{detailData.receiveOrgId }}</el-col>
           </el-row>
           <el-row>
              <el-col :span="8">职务：{{detailData.receiveDuty}}</el-col>
              <el-col :span="8">楼号：{{detailData.buildId}}</el-col>
              <el-col :span="8">房间号：{{detailData.roomId}}</el-col>
           </el-row>
           <el-row>
              <el-col :span="8">创建时间：{{detailData.createTime}}</el-col>
              <el-col :span="8">完成时间：{{detailData.updateTime}}</el-col>
           </el-row>
           <el-row>
              <el-col :span="8">工单状态：{{detailData.status | status}}</el-col>
              <el-col :span="8">备注信息：{{detailData.content}}</el-col>
           </el-row>
           <el-col>
              <h3 v-show="wximg == true">待维修/维修中的图片 </h3> <div class="img" @click="pngsa(item)" v-for="item in accomplishImage" :key="item.id"><img :src="item"/></div>
           </el-col>
           <el-col>
             <h3 v-show="wximg == true">维修完成的图片</h3> <div class="img" v-for="item in image" :key="item.id"><img :src="item"/></div>
           </el-col>

        </el-row>
        <el-dialog :visible.sync="dialogTableVisible">
            <img :src="amplification" alt="">
        </el-dialog>
     </el-row>
  </div>
</template>

<script>
   import { urlObj } from '@/api/interface'
   import {hotelMan} from "@/api/interface/oms";
   export default {
      data(){
         return{
            detailData: {},
            id:'',
            image:[],
            img:'',
            accomplishImage:[],
            accomplishImg:'',
            wximg:false,
            dialogTableVisible:false,
            amplification:''
         }
      },
     created() {
        this.id = this.$route.query.id
        this.handleDetail()
     },
     methods: {
        // 返回上页
        goBack(){
           this.$router.go(-1)
        },
        handleDetail(){
           const url = hotelMan.Workorderdetails + `/${this.id}`
           this.$axios.get(url).then(res => {
              this.detailData = res.records

              if (res.records.attachments.length === 0){
                  this.wximg = false
              }
              if (res.records.attachments.length !== 0){
                 this.wximg = true
              }
              let image = []
              image = res.records.attachments

              image.map(item=>{
                 if (item.category === 'disposed'){
                    this.img = window.getResourcesHost() + item.location+'/'+item.formatName
                    this.image.push(this.img)
                 }
                 if (item.category === 'undisposed'){
                    this.accomplishImg = window.getResourcesHost() + item.location+'/'+item.formatName
                    this.accomplishImage.push(this.accomplishImg)

                 }
              })

           })
        },
        pngsa(item){
          this.dialogTableVisible = true
          if(this.amplification !== ''){
            this.amplification = ''
          }
          if(this.amplification == ''){
          this.amplification = item
          }
        }
     },
     filters:{
        status(val){
           if (val == 'NO_RECEIVED'){
              return '待接单'
           }
           if (val == 'ORDER_RECEIVED'){
              return '已接单'
           }
           if (val == 'SERVICE_FINISHED'){
              return '已完成'
           }
        },
        typeCode(val){
           if (val == 'roomService'){
              return '客房服务'
           }
           if (val == 'suddenTask'){
              return '突发性任务'
           }
           if (val == 'plainTask'){
              return '计划任务'
           }
        }
     }
   }
</script>

<style scoped lang="scss">
   .info-main{
      margin-bottom: 15px;
      line-height: 40px;
      padding-left: 30px;
      font-size: 14px;
   }
   .cont{height: auto;}
   .img{float: left;margin: 0px 10px}
   .img img{width: 112px; height: 112px;}

   .el-dialog__body img{width: 100%;height: 683px;}
/deep/.el-dialog__body{padding: 0px !important; height: 683px;}
/deep/ .el-dialog__headerbtn{top: 10px;}
/deep/ .el-dialog__header{margin: 0px;}
</style>

